import React from 'react';
import Leaderboard from './Leaderboard';
import "./App.css"

function App() {
    return (
        <div style={{ backgroundColor: '#1e1f22', minHeight: '100vh' }}>
            <Leaderboard />
        </div>
    );
}

export default App;
