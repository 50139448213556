import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar, Box, Typography } from '@mui/material';

const formatDuration = (minutes) => {
    if (!minutes) return "0min"; // Přidáno pro zpracování nulových, null, nebo undefined hodnot

    const days = Math.floor(minutes / 1440);
    const hours = Math.floor((minutes % 1440) / 60);
    const mins = minutes % 60;

    let result = '';
    if (days > 0) result += `${days}d `;
    if (hours > 0) result += `${hours}h `;
    if (mins > 0 || result === '') result += `${mins}min`;

    return result.trim();
}

const formatNumber = (num, isCWPoints = false) => {
    const sign = num < 0 ? '-' : '';
    const absNum = Math.abs(num);

    const formatWithOptionalDecimal = (value, divider, unit) => {
      const dividedValue = value / divider;
      let formattedNumber;
      if (Math.floor(dividedValue) !== dividedValue) {
        formattedNumber = isCWPoints ? dividedValue.toFixed(1) : dividedValue.toFixed(2);
      } else {
        formattedNumber = dividedValue.toFixed(0);
      }
      return sign + formattedNumber + unit;
    };

    if (isCWPoints) {
      if (absNum < 1000) return sign + absNum.toFixed(0);
      if (absNum < 1000000) return formatWithOptionalDecimal(absNum, 1000, 'k');
      if (absNum < 1000000000) return formatWithOptionalDecimal(absNum, 1000000, 'M');
      if (absNum < 1000000000000) return formatWithOptionalDecimal(absNum, 1000000000, 'B');
      return formatWithOptionalDecimal(absNum, 1000000000000, 'T');
    } else {
      if (absNum < 1000) return sign + absNum.toFixed(0);
      if (absNum < 1000000) return sign + (absNum / 1000).toFixed(0) + 'k';
      if (absNum < 1000000000) return formatWithOptionalDecimal(absNum, 1000000, 'M');
      if (absNum < 1000000000000) return formatWithOptionalDecimal(absNum, 1000000000, 'B');
      return formatWithOptionalDecimal(absNum, 1000000000000, 'T');
    }

    return sign + absNum; 
};


function Leaderboard() {
    const [players, setPlayers] = useState([]);

    useEffect(() => {
        axios.get('https://dashboard.varmi.click/api/leaderboard')
            .then(response => {
                setPlayers(response.data);
                
            })
            .catch(error => console.log(error));
    }, []);


    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh" sx={{ bgcolor: 'background.dark' }}>
            <img src="https://i.ibb.co/WKqnnL8/varmilogopng.png" alt="logo" style={{ marginBottom: 50, maxWidth: "300px" }} />
            <TableContainer component={Paper} sx={{ maxWidth: '1000px', borderRadius: "12px", maxHeight: "73%", overflow: 'auto', bgcolor: '#333333', color: 'grey.300' }}>
                <Table stickyHeader aria-label="leaderboard" sx={{ '.MuiTableCell-root': { borderColor: '#444' } }}>
                    <TableHead>
                        <TableRow sx={{ bgcolor: '#2B2D31' }}>
                            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem', color: 'white', bgcolor: "#2B2D31", pl: 4 }}>Rank</TableCell>
                            <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: '1rem', color: 'white', bgcolor: "#2B2D31" }}>Username</TableCell>
                            <TableCell align="right" sx={{ fontWeight: 'bold', fontSize: '1rem', color: 'white', bgcolor: "#2B2D31" }}>Messages</TableCell>
                            <TableCell align="right" sx={{ fontWeight: 'bold', fontSize: '1rem', color: 'white', bgcolor: "#2B2D31" }}>Voice</TableCell>
                            <TableCell align="right" sx={{ fontWeight: 'bold', fontSize: '1rem', color: 'white', bgcolor: "#2B2D31" }}>Current XP</TableCell>
                            <TableCell align="right" sx={{ fontWeight: 'bold', fontSize: '1rem', color: 'white', bgcolor: "#2B2D31" }}>Total XP</TableCell>
                            <TableCell align="right" sx={{ fontWeight: 'bold', fontSize: '1rem', color: 'white', bgcolor: "#2B2D31", pr: 4 }}>Level</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {players.map((player, index) => (
                            <TableRow key={player.userId} sx={{ bgcolor: '#313338', '.MuiTableCell-root': { borderColor: '#444' } }}>
                                <TableCell component="th" scope="row" sx={{ fontWeight: 'bold', fontSize: '1.2rem', color: 'white', pl: 4 }}>#{index + 1}</TableCell>
                                <TableCell align="left">
                                    <Box display="flex" alignItems="center">
                                        <Avatar src={player.avatarUrl} sx={{ marginRight: 2 }} />
                                        <Typography variant="body2" sx={{ fontWeight: '600', fontSize: '1rem', color: 'white' }}>{player.userName}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell align="right" sx={{ fontSize: '1rem', color: 'white' }}>{formatNumber(player.messageCount, true)}</TableCell>
                                <TableCell align="right" sx={{ fontSize: '1rem', color: 'white' }}>{formatDuration(player.voiceMinutes, true)}</TableCell>
                                <TableCell align="right" sx={{ fontSize: '1rem', color: 'white' }}>{`${formatNumber(player.xp, true)}/${formatNumber(player.level * 100 + 100, true)}`}</TableCell>
                                <TableCell align="right" sx={{ fontSize: '1rem', color: 'white' }}>{formatNumber(((player.level - 1) / 2) * (100 + 100 * (player.level - 1)), true)}</TableCell>
                                <TableCell align="right" sx={{ fontWeight: 'bold', fontSize: '1rem', color: 'white', pr: 4 }}>{player.level}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default Leaderboard;
